.cart-wrapper {
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: white;
    overflow: scroll;
    color: black;
    background-image: url("../../assets/bg-pizza.png");
    -webkit-background-size: cover;
    background-size: cover;
}

.cart-wrapper::before{
    background-color: black;
    content: '';
    opacity: 0.7;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.cart-list {
    display: grid;
    grid-template-columns: 1fr;
    width: 98%;
    margin: 0 auto;
    color: #000;
}

.cart-header {
    margin: 20px auto;
    text-align: center;
    position: relative;
}

.cart-close-btn {
    font-size: 0px;
    float: right;
    cursor: pointer;
    z-index: 201;
    border: none;
    outline: none;
}
.cart-close-btn:after {
    content: "\00d7";
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 200;
    border: none;
    font-size: 50px;
    outline: none;
    color: white;
}

@media screen and (max-width: 390px){
    .cart-list {
        padding-left: 2px;
        width: 100%;
    }
}
