.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px 10px 10px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 200;
    background-color: #fff;
    background-image: url("../../assets/bg-pizza.png");
    color: white;
}

.form::before{
    background-color: black;
    content: '';
    opacity: 0.6;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.form ol {
    padding-left: 40px;
    color: darkorange;
}

.input {
    width: 100%;
    padding: 10px;
    margin-top: 15px;
}
