* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Verdana, Arial, sans-serif;
}

body {
    background-image: url("assets/bg-pizza.png");
    -webkit-background-size: contain;
    background-size: contain;
    position: relative;
    background-color: #fff;
    background-repeat-y: repeat;
    background-repeat-x: repeat;
    /*align-items: center;*/
}

body::before{
    background-color: black;
    content: '';
    opacity: 0.4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
    z-index: -1;
    background-repeat-y: repeat;
    background-repeat-x: repeat;
}

.App {
    align-items: center;
}

:target::before {
    content: "";
    display: block;
    height: 70px;
}
