.pizza-modal__wrapper {
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    background-color: white;
    overflow: scroll;
    color: #e3e2e2;
    background-image: url("../../assets/bg-pizza.png");
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat-y: repeat;
    background-repeat-x: no-repeat;
    margin: 0 auto;
}

.pizza-modal__wrapper::before{
    background-color: black;
    content: '';
    opacity: 0.7;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.pizza-modal__close-btn {
    font-size: 0px;
    float: right;
    cursor: pointer;
    z-index: 201;
    border: none;
    outline: none;
}
.pizza-modal__close-btn:after {
    content: "\00d7";
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 200;
    border: none;
    font-size: 50px;
    outline: none;
    color: white;
}

.modal__pizza-pizza {
    min-width: 320px;
    max-width: 500px;
    width: 98%;
    margin: 0 auto;
}


.pizza-modal__img {
    width: 150px;
    height: 150px;
    margin: 20px auto;
    position: relative;
}

.pizza-modal__img img {
    width: auto;
    margin: 0 auto;
    display: block;
    width: 150px;
    height: 150px;
}

.pizza-modal__count-badge {
    position: absolute;
    top: -10px;
    right: -20px;
    background-color: #e16500;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 15px;
}

.pizza-modal__button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
    width: 50%;
}

.pizza-modal__description {
    color: #9d9da2;
}

.pizza-modal__price-tag {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
    margin-top: 50px;
    position: relative;
}

.pizza-modal__button-container {
    justify-self: right;
    position: absolute;
}

.additives-title {
    margin: 20px 0;
    text-align: center;
}

.additive-item {
    text-align: center;
    margin: 0 auto;
}
