.button {
    padding: 10px 15px;
    border: 1px solid white;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    width: 100%;
    background: darkorange;
    color: white;
    /*width: 148px;*/
    margin: 0 1px;
}

.button.add {
    background: darkorange;
}

.button.remove {
    background: #212020;
}

.button.checkout {
    background: var(--tg-theme-button-color);
}

.button:active {
    transform: scale(.98);
}

.button:hover {
    filter: brightness(85%);
}

@media screen and (max-width: 350px) {

}
