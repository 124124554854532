.header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: fixed;
    background-color: #ffffff;
    opacity: 1;
    z-index: 5;
    box-shadow: 2px 4px rgba(128, 128, 128, 0.09);
    border-top: 1px solid gainsboro;
}

.burger__top-left-btn {
    width: 25px;
    fill: darkorange;
    position: absolute;
    left: 20px;
    cursor: pointer;
}

.username {
    margin-right: auto;
    margin-left: 45px;
    color: black;
}

.usertext {
    color: darkorange;
}

.cart-btn {
    margin: 0 25px 0 auto;
    cursor: pointer;
    position: relative;
}

.cart-badge {
    background-color: darkorange;
    position: absolute;
    top: -5px;
    right: -10px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    color: white;
    font-size: 6px;
    padding: 3px 2px 2px 5px;
}
