/* sidebar navi menu */

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 1.5rem;
    height: 1.5rem;
    left: 1rem;
    top: .8rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #e19f00;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
}

/* General sidebar styles */
.bm-menu {
    background: whitesmoke;
    padding: 2.5em 1.5em 2rem;
    font-size: 1.15em;
    height: 90%;
    width: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: rgba(55, 58, 71, 0.18);
}

/* Wrapper for item list */
.bm-item-list {
    color: #7a7c83;
    padding: 0;
    margin: 0;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 33%);
    grid-gap: 5px;
    row-gap: 40px;
}

/* Individual item */
.bm-item {
    display: inline-block;
    cursor: pointer;
    padding-bottom: 10px;
    height: 50px;
}

.bm-item img {
    width: 100%;
    height: 100%;
}

.bm-item:hover {
    color: white;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(100, 100, 100, 0.76);
    top: 0;
}

.menu-item {
    color: #7a7c83;
    text-decoration: none;
    font-size: 8px;
}

.menu-item:hover {
    color: darkorange;
    cursor: pointer;
}

/* custom button styles */

.burger__custom-btn {
    width: 50px;
    position: absolute;
    right: -50px;
    top: 30%;
    background-color: darkorange;
    height: 100px;
    border-radius: 0px 50px 50px 0px;
}

.burger__custom-btn .arrow {
    height: 35px;
    width: 35px;
    margin: 32px auto 25px 7px;
    position: relative;
    font-size: 30px;
    color: white;
}


.item-text {
    text-align: center;
    height: 30px;
}
